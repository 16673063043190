import { NumberOrString } from "../type/common";
import { InspectionStage, InspectionStatus } from "../type/inspection";
import { ProductItemImage } from "./order";

export const innspectionData: InspectionTable = {
  id: 0,
  qc_number: "",
  received_date: "",
  receiver: 0,
  request_number: [],
  inspection_status: 0,
  __show: {
    inspection_status: "",
  },
  inspection_number: "",
  inspection_date: "",
  relation_shipment: {
    service_provider: 0,
    pick_up_by: "",
    tracking_no: "",
    __show: {
      service_provider: "",
    },
    quantity: 0,
    result: {
      passed: 0,
      failed: 0,
      missing: 0,
    },
    articles: {
      brand: "",
      sku_quantity: 0,
    },
  },
};

export const inspectionDetail: InspectionDetail = {
  inspection_status: InspectionStatus.Pending,
  received_date: "",
  qc_number: "",
  batch_number: "",
  inspection_date: "",
  inspected_date: "",
  warehouse_name: "",
  merchant_request_number: [],
  merchant_order_number: [],
  first_inspection: {
    name: "",
    date: "",
  },
  second_inspection: {
    name: "",
    date: "",
  },
  relation_shipment: {
    tracking_no: "",
    sku_quantity: 1,
    demand_quantity: 0,
    passed_quantity: 0,
    failed_quantity: 0,
    missing_quantity: 0,
    merchant_name: "",
    relation_delivery_from_location: {
      name: "",
      country_iso_2: "",
      city: "",
      zip_code: "",
      street: "",
      business_entity_id: 0,
      is_default: 0,
      location_str: "",
    },
  },
  __show: {
    inspection_status: "",
  },
};

export const inspectionOverview: InspectionOverview = {
  article: [],
  mismatched: [],
  missing: [],
  inspection_status: InspectionStatus.Pending,
  id: 0,
  print: 0,
  check_flag: 0,
  stock_in_flag: 0,
  submit_qc_flag: 0,
  checked_date: "",
  checked_user: 0,
  double_sale: 0,
  __show: {
    checked_user: "",
  },
};

export const inspectionDetailInspectionItemData: InspectionDetailInspectionItem =
  {
    id: 0,
    gallery: [],
    name: "",
    name_en: "",
    brand_article_no: "",
    order_number: "",
    original_color: "",
    product_identifier_id: "0",
    reason: 0,
    reviewer: 0,
    review_date: "",
    size: "",
    color: 0,
    brand_id: 0,
    type: 0,
    identifier_code: "",
    inspection_date: "",
    is_additional: 0,
    is_stock: 0,
    match_date: "",
    match_manager: 0,
    match_status: 0,
    inspection_manager: 0,
    second_inspection_date: "",
    second_inspection_manager: 0,
    image_quantity: 0,
    inspection_stage: 0,
    inspection_result: 0,
    inspection_notes: "",
    second_inspection_result: 0,
    second_inspection_notes: "",
    scan_number: "",
    stock_in_id: "",
    guid_exception: [],
    __show: {
      brand_id: "",
      size: "",
      capacity: "",
      color: "",
      color_swatch_code: "",
      inspection_stage: "",
      match_manager: "",
      inspection_result: "",
      reason: "",
      reviewer: "",
      type: "",
      inspection_manager: "",
      second_inspection_manager: "",
      second_inspection_result: "",
    },
    position: "",
    printDisabled: false,
  };

export interface InspectionTable {
  id: 0;
  qc_number: string;
  received_date: string;
  receiver: number;
  request_number: string[];
  inspection_status: NumberOrString;
  inspection_number: string;
  inspection_date: string;
  relation_shipment: InspectionShipment;
  __show: InspectionInfoShow;
}

interface InspectionInfoShow {
  inspection_status: string;
}

interface InspectionResult {
  passed: number;
  failed: number;
  missing: number;
}

interface InspectionRarticles {
  brand: string;
  sku_quantity: number;
}

interface InspectionShipment {
  service_provider: NumberOrString;
  pick_up_by: string;
  tracking_no: string;
  __show: InspectionShipmentShow;
  quantity: number;
  result: InspectionResult;
  articles: InspectionRarticles;
}

interface InspectionShipmentShow {
  service_provider: string;
}

export interface InspectionDetail {
  inspection_status: InspectionStatus;
  received_date: string;
  qc_number: string;
  batch_number?: string;
  inspection_date: string;
  inspected_date: string;
  merchant_request_number: string[];
  merchant_order_number: string[];
  warehouse_name: string;
  first_inspection: InspectionDetailInspection;
  second_inspection: InspectionDetailInspection;
  relation_shipment: InspectionDetailRelationShipment;
  __show: InspectionDetailShow;
}

interface InspectionDetailShow {
  inspection_status: string;
}

interface InspectionDetailInspection {
  name: string;
  date: string;
}

interface InspectionDetailRelationShipment {
  tracking_no: string;
  sku_quantity: number;
  demand_quantity: number;
  passed_quantity: number;
  failed_quantity: number;
  missing_quantity: number;
  merchant_name: string;
  relation_delivery_from_location: InspectionDetailRelationShipmentFromLocation;
}
interface InspectionDetailRelationShipmentFromLocation {
  name: string;
  country_iso_2: string;
  city: string;
  zip_code: string;
  street: string;
  business_entity_id: number;
  is_default: number;
  location_str: string;
}

export interface InspectionDetailInspectionItem {
  id: NumberOrString;
  gallery: ProductItemImage[];
  name: string;
  name_en: string;
  brand_article_no: string;
  product_identifier_id: string;
  order_number: string;
  original_color: string;
  reviewer: NumberOrString;
  reason: NumberOrString;
  review_date: string;
  size: string;
  color: NumberOrString;
  brand_id: NumberOrString;
  type: NumberOrString;
  identifier_code: string;
  inspection_date: string;
  is_additional: number;
  is_stock: number;
  match_date: string;
  match_manager: NumberOrString;
  match_status: NumberOrString;
  inspection_manager: NumberOrString;
  second_inspection_date: string;
  second_inspection_manager: NumberOrString;
  image_quantity: number;
  inspection_stage: NumberOrString;
  inspection_result: NumberOrString;
  inspection_notes: string;
  second_inspection_result: NumberOrString;
  second_inspection_notes: string;
  scan_number: string;
  stock_in_id: NumberOrString;
  guid_exception: string[];
  __show: InspectionDetailInspectionItemShow;
  position: string;
  printDisabled: boolean;
}

interface InspectionDetailInspectionItemShow {
  brand_id: string;
  size: string;
  capacity: string;
  color: string;
  color_swatch_code: string;
  inspection_stage: string;
  match_manager: string;
  inspection_result: string;
  reason: string;
  reviewer: string;
  type: string;
  inspection_manager: string;
  second_inspection_manager: string;
  second_inspection_result: string;
}

interface InspectionOverview {
  article: any[];
  mismatched: any[];
  missing: any[];
  inspection_status: number;
  id: NumberOrString;
  print: number;
  check_flag: number;
  stock_in_flag: number;
  submit_qc_flag: number;
  checked_date: string;
  checked_user: NumberOrString;
  double_sale: number;
  __show: {
    checked_user: string;
  };
}

export interface InspectionInfo {
  id: number;
  identifier_code: string;
  inspection_stage: InspectionStage;
  gallery: ProductItemImage[];
  name_en: string;
  brand_article_no: string;
  original_color: string;
  size: string;
  color: string;
  order_number: string;
  order_item_position: string;
  brand_id: number;
  gtin_newest: string;
  origin_country_newest: string;
  __show: InspectionInfoShowData;
}

interface InspectionInfoShowData {
  brand_id: string;
  color: string;
  color_swatch_code: string;
  size: string;
}

export const packageGenerationGuid = {
  position: "",
  identification_code: "",
  package_number: "",
  package_barcode: "",
  product_barcode: "",
};

export const packageSequencePreview = {
  barcode: "",
  package_number: "",
  logistics_document_count: 0,
  sequence_value: 0,
};

export const packageLogisticsDocument = {
  logistics_doc_id: 0,
  tracking_no: "",
  base64_image: "",
};

export type PackageSequencePreview = typeof packageSequencePreview;

export type PackageGenerationGuid = typeof packageGenerationGuid;

export type PackageLogisticsDocument = typeof packageLogisticsDocument;
export enum InspectionProductException {
  BrandArticleNo = "BrandArticleNo",
  EanCode = "EanCode",
  Material = "Material",
  OriginalCountry = "OriginalCountry",
  Size = "Size",
  InvoiceSku = "InvoiceSku",
  PanoramaImageError = "PanoramaImageError",
  Product = "Product",
}
