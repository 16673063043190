
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { CommonHtmlType } from "@/core/directive/type/common";
import { ApiBase, ApiInspection } from "@/core/api";
import { modalShowListener } from "@/core/directive/function/common";
import { TaggingItem } from "@/core/directive/interface/common";
import { InspectionResult } from "@/core/directive/type/inspection";
import {
  getInspectionResultBgStyleMap,
  getInspectionResultDescriptionMap,
  getInspectionResultSvgMap,
  getInspectionResultSvgStyleMap,
} from "@/core/directive/function/inspection";
import { User } from "@/store/modules/AuthModule";
import store from "@/store";

export default defineComponent({
  name: "warehouse-management-inspection-mismatched-sku-modal",
  props: {
    id: {
      type: String,
      default: "",
    },
    result: {
      type: Number,
      default: 0,
    },
    maxQty: {
      type: Number,
      default: 0,
    },
    batchNumber: {
      type: String,
      default: "",
    },
  },
  components: {},
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const route = useRoute();
    const loading = ref<boolean>(false);
    const missingResultRef = ref<CommonHtmlType>(null);
    const formRef = ref<null | HTMLFormElement>(null);
    const batchId = ref("");

    const formData = ref({
      radio: "",
      qty: 0,
    });

    const rules = ref({
      radio: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
      qty: [
        {
          required: true,
          message: t("common.isRequired"),
          trigger: "change",
        },
      ],
    });

    const options = ref({
      result: [] as TaggingItem[],
      toggler: false,
      sources: [] as string[],
      sourcesIndex: -1,
      sourcesSlide: 1,
    });

    const userInfo = ref<User>(store.getters.currentUser);

    watch(
      () => store.getters.currentUser,
      (newVal) => {
        userInfo.value = newVal;
      }
    );

    watch(
      () => props.batchNumber,
      (newVal) => {
        batchId.value = newVal;
      }
    );

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["wms_inspection_result"],
      });
      if (data.code == 0) {
        let arr: TaggingItem[] = [];
        data.data.wms_inspection_result.items.map((item) => {
          if (
            Number(item.value) === InspectionResult.CreatedBackOrder ||
            Number(item.value) === InspectionResult.InternalLoss ||
            Number(item.value) === InspectionResult.Received
          ) {
            arr.push(item);
          }
        });
        options.value.result = arr;
      }
    };

    const getFormInfo = () => {
      loading.value = true;
      Promise.all([getTaggingData()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid: boolean) => {
        if (valid) {
          if (loading.value) return;
          submitRadio();
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const submitRadio = async () => {
      loading.value = true;
      const { data } =
        await ApiInspection.updateInspectionMissingInspectionResult({
          sku_identifier: props.id,
          inspection_result: formData.value.radio,
          qty: formData.value.qty,
          batch_number: batchId.value,
        });
      loading.value = false;
      if (data.code == 0) {
        showFormSubmitSuccessMsg(() => {
          emit("update-list");
          hideModal(missingResultRef.value);
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    const handleDiscard = () => {
      hideModal(missingResultRef.value);
      resetForm();
    };

    const resetForm = () => {
      formRef.value?.resetFields();
      emit("reset-form");
    };

    const init = () => {
      setTimeout(() => {
        getFormInfo();
        formData.value.radio = props.result === 0 ? "" : String(props.result);
      }, 0);
    };

    onMounted(() => {
      modalShowListener(missingResultRef.value, () => {
        init();
      });
      modalHideListener(missingResultRef.value, () => {
        resetForm();
      });
    });

    return {
      t,
      getInspectionResultSvgMap,
      getInspectionResultSvgStyleMap,
      getInspectionResultBgStyleMap,
      getInspectionResultDescriptionMap,
      formRef,
      missingResultRef,
      props,
      loading,
      formData,
      batchId,
      rules,
      options,
      userInfo,
      handleDiscard,
      resetForm,
      submit,
    };
  },
});
