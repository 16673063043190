
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  onUpdated,
  ref,
  useAttrs,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import InboundReceivingPackageModal from "@/views/warehouse-management/inbound/ReceivingPackage.vue";
import { ApiInspection } from "@/core/api";
import mixin from "@/mixins";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import {
  commonBackToList,
  formatDateTime,
  setModuleBCN,
} from "@/core/directive/function/common";
import {
  InspectionStage,
  InspectionStatus,
} from "@/core/directive/type/inspection";
import MismatchedSku from "./MismatchedSku.vue";
import MissingResult from "./MissingResult.vue";
import { MenuComponent } from "@/assets/ts/components";
import {
  inspectionDetail,
  InspectionDetail,
  inspectionOverview,
} from "@/core/directive/interface/inspection";
import ProductInfo from "@/components/layout/ProductInfo.vue";
import SkuInfo from "@/components/layout/SkuInfo.vue";
import { PrinterConfigTaskDocumentContent } from "@/core/directive/interface/print";
import ExceptionProduct from "./ExceptionProduct.vue";
import PrintPreview from "./PrintPreview.vue";
import { ElNotification } from "element-plus";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";
import UpdateDoubleSaleArticle from "./UpdateDoubleSaleArticle.vue";
import EditDoubleSaleArticleGuid from "./editDoubleSaleArticleGuid.vue";
import {
  checkProductBarcode,
  setProductInfo,
} from "@/core/directive/function/inspection";

export default defineComponent({
  name: "warehouse-management-inspection-overview",
  components: {
    InboundReceivingPackageModal,
    MismatchedSku,
    MissingResult,
    ProductInfo,
    SkuInfo,
    ExceptionProduct,
    PrintPreview,
    PermissionCommon,
    UpdateDoubleSaleArticle,
    EditDoubleSaleArticleGuid,
  },
  setup(props, context) {
    const { t } = useI18n();
    const attrs = useAttrs();
    const currentInstance: any = getCurrentInstance();

    const route = useRoute();
    const router = useRouter();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const loading = ref<boolean>(false);
    const detailLoading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const itemId = ref("");
    const itemResult = ref(0);
    const itemQty = ref(0);
    const itemScanNumber = ref("");
    const itemIndex = ref(-1);

    const formData = ref(Object.assign({}, inspectionOverview));

    const inspectionFormData = ref(Object.assign({}, inspectionDetail));

    const exceptionProductRef = ref();
    const printPreviewRef = ref();
    const receivingPackageRef = ref();
    const updateDoubleSaleArticleModalRef = ref();

    watch(
      () => attrs.loading,
      (newValue) => {
        detailLoading.value = newValue as boolean;
        const data = attrs.detailData as InspectionDetail;
        inspectionFormData.value = data;
      }
    );

    const options = ref({
      articleMap: new Map<string, any>([]),
      mismatchedMap: new Map<string, any>([]),
      showEditGuids: false,
      guids: [] as any[],
    });

    const rules = ref({
      // status: [
      //   {
      //     required: true,
      //     message: "Status is required",
      //     trigger: "change",
      //   },
      // ],
    });

    const getStockInTotalQty = computed(() => {
      let demandQty = 0,
        receivedQty = 0,
        missingQty = 0,
        stockedQty = 0;
      formData.value.article.forEach((item) => {
        demandQty += item.demand_quantity;
        receivedQty += item.received_quantity;
        missingQty += item.missing_quantity;
        stockedQty += item.stocked_quantity;
      });
      return {
        demandQty,
        receivedQty,
        missingQty,
        stockedQty,
      };
    });

    const isEditStockIn = computed(() => {
      let flag = false;
      if (formData.value.check_flag === 1) {
        flag = true;
      }
      return flag;
    });

    const isDisabledSubmitQc = computed(() => {
      let flag = false;
      const articleValues = Array.from(options.value.articleMap.values());
      const mismatchValues = Array.from(options.value.mismatchedMap.values());
      if (articleValues.length !== formData.value.article.length) {
        flag = true;
      }
      // else if (mismatchValues.length !== formData.value.mismatched.length) {
      //   flag = true;
      // }
      else {
        let isFlag = false;
        formData.value.article.map((item, index) => {
          if (articleValues[index].qtyRecords !== item.received_quantity) {
            isFlag = true;
            flag = true;
          } else if (
            articleValues[index].qtyRecords +
              articleValues[index].missing_quantity <
            articleValues[index].demand_quantity
          ) {
            isFlag = true;
            flag = true;
          }
        });
        // if (!isFlag) {
        //   formData.value.mismatched.map((item, index) => {
        //     if (mismatchValues[index].qtyRecords !== item.qty) {
        //       flag = true;
        //     }
        //   });
        // }
      }
      return flag;
    });

    const getExceptionProduct = (data, number) => {
      let isflag = pushArticleData.value(number, data.sku_identifier);
      if (!isflag) {
        let flag = pushMismatchedData.value(number, data.sku_identifier);
        if (!flag) {
          formData.value.mismatched.push(setMismatchedProductData(data));
          // warningTip(number);
        } else {
          // passedTip(number);
        }
        warningTip(number);
      } else {
        passedTip(number);
      }
      receivingPackageRef.value?.modalShow();
      receivingPackageRef.value?.restart();
    };

    const showReceivingPackage = () => {
      // receivingPackageRef.value?.modalShow();
      receivingPackageRef.value?.restart();
    };

    const hideReceivingPackage = () => {
      receivingPackageRef.value?.modalHide();
    };

    const editDoubleSaleArticle = (index) => {
      const value = getDoubleSaleArticleItemGuids.value(index).length;
      const qty = formData.value.article[index].received_quantity;
      if (qty > value) {
        formData.value.article[index].received_quantity -= 1;
      } else {
        if (value === 0) return false;
        itemIndex.value = index;
        // updateDoubleSaleArticleModalRef.value?.initData(
        //   formData.value.article[index].guids
        // );
        options.value.guids = getEditDoubleSaleArticleGuids.value(index);
        options.value.showEditGuids = true;
      }
    };

    const hideEditDoubleSaleArticle = () => {
      options.value.showEditGuids = false;
      itemIndex.value = -1;
    };

    const getEditDoubleSaleArticleGuids = computed(() => {
      return (index) => {
        let arr: any[] = [];
        for (let key in formData.value.article[index].guids) {
          if (formData.value.article[index].guids[key] === 1) {
            arr.push({
              guid: key,
              isflag: true,
            });
          }
        }
        return arr;
      };
    });

    const editDoubleSaleArticleAdd = (index) => {
      formData.value.article[index].received_quantity += 1;
    };

    const updateDoubleSaleArticle = (data: any[]) => {
      for (let key in formData.value.article[itemIndex.value].guids) {
        if (data.includes(key)) {
          formData.value.article[itemIndex.value].guids[key] = 0;
          formData.value.article[itemIndex.value].received_quantity -= 1;
        }
      }
      hideEditDoubleSaleArticle();
    };

    // const updateDoubleSaleArticle = (value) => {
    //   formData.value.article[itemIndex.value].guids[value] = 0;
    //   formData.value.article[itemIndex.value].received_quantity -= 1;
    // };

    const getDoubleSaleArticleItemGuids = computed(() => {
      return (index) => {
        let arr: string[] = [];
        if (formData.value.double_sale === 1) {
          for (let key in formData.value.article[index].guids) {
            if (formData.value.article[index].guids[key] === 1) {
              arr.push(key);
            }
          }
        }
        return arr;
      };
    });

    const getDoubleSaleArticleGuids = computed(() => {
      let arr: string[] = [];
      if (formData.value.double_sale === 1) {
        formData.value.article.map((item) => {
          for (let key in item.guids) {
            if (item.guids[key] === 1) {
              arr.push(key);
            }
          }
        });
      }
      return arr;
    });

    const editMismatchedSku = (item) => {
      itemId.value = item.sku_identifier;
      itemScanNumber.value = item.scan_number;
    };

    const editMissingResult = (item) => {
      itemId.value = item.sku_identifier;
      itemResult.value = item.inspection_result;
      itemQty.value = item.qty;
    };

    // missing qty
    const getMissingQtyTotal = computed(() => {
      let count = 0;
      formData.value.missing.map((item) => {
        count += item.qty;
      });
      return count;
    });

    // 入库产品
    const getArticleData = computed(() => {
      let arr: any[] = [];
      formData.value.article.map((item, index) => {
        arr.push({
          received_quantity: item.received_quantity,
          sku_identifier: item.sku_identifier,
          guids: getDoubleSaleArticleItemGuids.value(index),
        });
      });
      return arr;
    });

    // 未匹配数据
    const mismatchedData = computed(() => {
      let arr: any[] = [];
      formData.value.mismatched.map((item) => {
        arr.push({
          quantity: item.qty,
          sku_identifier: item.sku_identifier,
          scan_number: item.scan_number,
        });
      });
      return arr;
    });

    // 超出的产品
    const mismatchedSku = computed(() => {
      let arr: any[] = [];
      formData.value.mismatched.map((item) => {
        if (item.sku_identifier !== "") {
          arr.push({
            quantity: item.qty,
            sku_identifier: item.sku_identifier,
            scan_number: "",
          });
        }
      });
      return arr;
    });

    // checked
    const getArticleSubmit = computed(() => {
      let arr: any[] = [];
      getArticleData.value.forEach((item) => {
        let index = mismatchedSku.value.findIndex(
          (li) => String(item.sku_identifier) === String(li.sku_identifier)
        );
        if (index !== -1) {
          let quantity = mismatchedSku.value[index].quantity;
          item.received_quantity += quantity;
        }
        arr.push(item);
      });
      return arr;
    });

    // print
    const getMismatched = computed(() => {
      let scan_numbers: string[] = [],
        identifier_codes: string[] = [];
      formData.value.mismatched.map((item) => {
        if (item.scan_number) {
          scan_numbers.push(item.scan_number);
        }
        if (item.identifier_code) {
          item.identifier_code.map((sub) => {
            if (getArticleIdentifierCodes.value.indexOf(sub) === -1) {
              identifier_codes.push(sub);
            }
          });
        }
      });
      return {
        scan_numbers,
        identifier_codes,
      };
    });

    // print
    const getArticleIdentifierCodes = computed(() => {
      let arr: number[] = [];
      formData.value.article.map((item) => {
        item.identifier_code.map((sub) => {
          if (arr.indexOf(sub) === -1) {
            arr.push(sub);
          }
        });
      });
      return arr;
    });

    const printProduct = (contents: PrinterConfigTaskDocumentContent[]) => {
      currentInstance.proxy.emitter.emit("all-print", contents);
    };

    const getPrintedSheet = async (type, item) => {
      // item.printDisabled = true;
      let params;
      if (type === 0) {
        params = {
          identifier_code: item.identifier_code,
        };
      } else {
        params = {
          mismatched: item.scan_number ? [item.scan_number] : [],
          identifier_code: item.identifier_code ? item.identifier_code : [],
        };
      }
      printPreviewRef.value?.modalShow(params);
      // const { data } = await ApiInspection.printedSheet(params);
      // item.printDisabled = false;
      // if (data.code === 0) {
      //   let contents: PrinterConfigTaskDocumentContent[] = [];
      //   data.data.product.map((item) => {
      //     contents.push({
      //       templateURL: data.data.templateURL,
      //       signature:
      //         "19d6f7759487e556ddcdd3d499af087080403277b7deed1a951cc3d9a93c42a7e22ccba94ff609976c5d3ceb069b641f541bc9906098438d362cae002dfd823a8654b2b4f655e96317d7f60eef1372bb983a4e3174cc8d321668c49068071eaea873071ed683dd24810e51afc0bc925b7a2445fdbc2034cdffb12cb4719ca6b7",
      //       data: item,
      //     });
      //   });
      //   printProduct(contents);
      // } else {
      //   showServerErrorMsg(data);
      // }
    };

    const getPrintedSheetAll = async () => {
      const params = {
        mismatched: [],
        // mismatched: getMismatched.value.scan_numbers, // getMismatched.value.scan_numbers
        identifier_code: [
          ...getArticleIdentifierCodes.value,
          // ...getMismatched.value.identifier_codes,
        ],
      };
      printPreviewRef.value?.modalShow(params);
      // loading.value = true;
      // const { data } = await ApiInspection.printedSheet({
      //   article: getArticleStockInId.value,
      //   mismatched: [], // getMismatched.value.scan_numbers
      //   mismatched_id: getMismatched.value.stock_in_ids,
      // });
      // loading.value = false;
      // if (data.code === 0) {
      //   let contents: PrinterConfigTaskDocumentContent[] = [];
      //   data.data.product.map((item) => {
      //     contents.push({
      //       templateURL: data.data.templateURL,
      //       signature:
      //         "19d6f7759487e556ddcdd3d499af087080403277b7deed1a951cc3d9a93c42a7e22ccba94ff609976c5d3ceb069b641f541bc9906098438d362cae002dfd823a8654b2b4f655e96317d7f60eef1372bb983a4e3174cc8d321668c49068071eaea873071ed683dd24810e51afc0bc925b7a2445fdbc2034cdffb12cb4719ca6b7",
      //       data: item,
      //     });
      //   });
      //   printProduct(contents);
      // } else {
      //   showServerErrorMsg(data);
      // }
    };

    const checkedStockIn = async () => {
      loading.value = true;
      const { data } = await ApiInspection.InspectionItemCheck({
        id: route.params.id,
        article: getArticleData.value,
        mismatched: mismatchedData.value,
      });
      loading.value = false;
      if (data.code === 0) {
        getFromInfo();
      } else {
        showServerErrorMsg(data);
      }
    };

    const submitQuality = async () => {
      Swal.fire({
        text: t("inspection.submitQcTip"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          const { data } =
            await ApiInspection.updateInspectionSubmitQualityInspection({
              id: route.params.id,
            });
          loading.value = false;
          if (data.code === 0) {
            getFromInfo();
            context.emit("getFormData");
          } else {
            showServerErrorMsg(data);
          }
        }
      });
    };

    const stockIn = async () => {
      loading.value = true;
      const { data } = await ApiInspection.updateInspectionStockIn({
        id: route.params.id,
      });
      loading.value = false;
      if (data.code === 0) {
        getFromInfo();
        context.emit("getFormData");
      } else {
        showServerErrorMsg(data);
      }
    };

    const getInspectionItems = async () => {
      const { data } = await ApiInspection.getInspectionItems({
        id: route.params.id,
      });
      if (data.code === 0) {
        options.value.articleMap.clear();
        options.value.mismatchedMap.clear();
        data.data.article.forEach((item) => {
          item.printDisabled = false;
          item.qtyRecords = item.received_quantity;
          options.value.articleMap.set(item.sku_identifier, item);
        });
        data.data.mismatched.forEach((item) => {
          item.printDisabled = false;
          item.qtyRecords = item.qty;
          if (item.sku_identifier) {
            options.value.mismatchedMap.set(item.sku_identifier, item);
          } else {
            options.value.mismatchedMap.set(item.scan_number, item);
          }
        });
        formData.value = data.data;
        //Double自动清点
        if (data.data.double_sale == 1) {
          formData.value.article.forEach((item) => {
            item.received_quantity = item.demand_quantity;
          });
        }
      } else {
        showServerErrorMsg(data);
      }
    };

    // 未匹配时提示
    const warningTip = (number: string) => {
      ElNotification({
        title: "Received +1",
        message: number,
        type: "warning",
        customClass: "warning",
      });
    };

    // 匹配时提示
    const passedTip = (number: string) => {
      ElNotification({
        title: "Received +1",
        message: number,
        type: "success",
        customClass: "success",
      });
    };

    // double sale已匹配时提示
    const doubleSaleCheckedTip = () => {
      ElNotification({
        title: t("inspection.doubleSaleCheckedTip"),
        type: "warning",
        customClass: "warning",
      });
    };

    // 判断扫码后
    const updateNumber = (number: string) => {
      // let moreArr: any[] = [];
      // if (formData.value.double_sale === 1) {
      //   // Double Sale单
      //   formData.value.article.forEach((item) => {
      //     if (item.guids[number] !== undefined) {
      //       if (item.guids[number] === 0) {
      //         item.received_quantity += 1;
      //         passedTip(number);
      //       } else {
      //         doubleSaleCheckedTip();
      //       }
      //       item.guids[number] = 1;
      //     }
      //   });
      // } else {

      // }
      let isScanGuid = false;
      if (checkProductBarcode.value(number)) {
        const productInfo = setProductInfo(number);
        number = productInfo.identification_code;
      }
      const moreArr = formData.value.article.filter((item) => {
        // const sameEanData = item.extra_ean_codes.find(
        //   (row) => row.ean === number
        // );
        if (item.guids[number] !== undefined) {
          if (item.guids[number] === 0) {
            item.received_quantity += 1;
            passedTip(number);
          } else {
            doubleSaleCheckedTip();
          }
          item.guids[number] = 1;
          isScanGuid = true;
          return false;
        }
        const sameEanData = item.gtin === number;
        return (
          item.merchant_article_no === number ||
          item.brand_article_no === number ||
          sameEanData
        );
      });
      if (!isScanGuid) {
        if (moreArr.length === 0) {
          let flag = pushMismatchedData.value(number, "");
          if (!flag) {
            formData.value.mismatched.push(setMismatchedData(number));
            // warningTip(number);
          } else {
            // passedTip(number);
          }
          warningTip(number);
          showReceivingPackage();
        } else if (moreArr.length === 1) {
          let isflag = pushArticleData.value(number, "");
          if (isflag) {
            let flag = pushMismatchedData.value(number, "");
            if (!flag) {
              formData.value.mismatched.push(
                setMismatchedProductData(moreArr[0])
              );
              // warningTip(number);
            } else {
              // passedTip(number);
            }
            warningTip(number);
          } else {
            passedTip(number);
          }
          showReceivingPackage();
        } else {
          hideReceivingPackage();
          exceptionProductRef.value?.modalShow(moreArr, number);
        }
      } else {
        receivingPackageRef.value?.restart();
      }
    };

    const debounceScan = _.debounce(updateNumber, 100);

    const pushArticleData = computed(() => {
      return (number, sku_identifier) => {
        let flag = false;
        if (sku_identifier) {
          formData.value.article.forEach((item) => {
            // const sameEanData = item.extra_ean_codes.find(
            //   (row) => row.ean === number
            // );
            const sameEanData = item.gtin === number;
            if (
              (item.merchant_article_no === number ||
                item.brand_article_no === number ||
                sameEanData) &&
              item.sku_identifier === sku_identifier
            ) {
              if (item.demand_quantity <= item.received_quantity) {
                // maxArr.push(item);
              } else if (item.demand_quantity > item.received_quantity) {
                item.received_quantity += 1;
                flag = true;
              }
            }
          });
        } else {
          formData.value.article.forEach((item) => {
            // const sameEanData = item.extra_ean_codes.find(
            //   (row) => row.ean === number
            // );
            const sameEanData = item.gtin === number;
            if (
              item.merchant_article_no === number ||
              item.brand_article_no === number ||
              sameEanData
            ) {
              if (item.demand_quantity <= item.received_quantity) {
                flag = true;
              } else if (item.demand_quantity > item.received_quantity) {
                item.received_quantity += 1;
              }
            }
          });
        }
        return flag;
      };
    });

    const pushMismatchedData = computed(() => {
      return (number, sku_identifier) => {
        let flag = false;
        if (sku_identifier) {
          formData.value.mismatched.forEach((item) => {
            // const sameEanData = item?.extra_ean_codes?.find(
            //   (row) => row.ean === number
            // );
            const sameEanData = item.gtin === number;
            if (item.scan_number) {
              if (item.scan_number === number) {
                item.qty += 1;
                flag = true;
              }
            } else if (
              (item.merchant_article_no === number ||
                item.brand_article_no === number ||
                sameEanData) &&
              item.sku_identifier === sku_identifier
            ) {
              item.qty += 1;
              flag = true;
            }
          });
        } else {
          formData.value.mismatched.forEach((item) => {
            // const sameEanData = item?.extra_ean_codes?.find(
            //   (row) => row.ean === number
            // );
            const sameEanData = item.gtin === number;
            if (item.scan_number) {
              if (item.scan_number === number) {
                item.qty += 1;
                flag = true;
              }
            } else if (
              item.merchant_article_no === number ||
              item.brand_article_no === number ||
              sameEanData
            ) {
              item.qty += 1;
              flag = true;
            }
          });
        }
        return flag;
      };
    });

    const setMismatchedData = (number) => {
      return {
        scan_number: number,
        qty: 1,
        gallery: [],
        stock_in_id: "",
        inspection_stage: InspectionStage.WaitingInspection,
        extra_ean_codes: [],
        sku_identifier: "",
        name: "",
        name_en: "",
        brand_article_no: "",
        merchant_article_no: "",
        gtin: "",
        original_color: "",
        size: 0,
        identifier_code: [],
        color: 0,
        brand_id: 0,
        __show: {
          brand_id: "",
          capacity: "",
          size: "",
          color: "",
          color_swatch_code: "",
        },
        isAdd: true,
      };
    };

    const setMismatchedProductData = (item) => {
      return {
        scan_number: "",
        qty: 1,
        gallery: item.gallery,
        stock_in_id: item.stock_in_id,
        inspection_stage: InspectionStage.WaitingInspection,
        sku_identifier: item.sku_identifier,
        name: item.name,
        name_en: item.name_en,
        brand_article_no: item.brand_article_no,
        merchant_article_no: item.merchant_article_no,
        gtin: item.gtin,
        extra_ean_codes: item.extra_ean_codes,
        original_color: item.original_color,
        size: item.size,
        identifier_code: item.identifier_code,
        color: item.color,
        brand_id: item.brand_id,
        __show: item.__show,
        isAdd: true,
      };
    };

    const getFromInfo = async () => {
      loading.value = true;
      Promise.all([getInspectionItems()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          // if (loading.value) return;
          // loading.value = true;
          // if (submitButton.value) {
          //   submitButton.value.setAttribute("data-kt-indicator", "on");
          // }
          // ApiShipments.updateShipment(formData.value)
          //   .then(({ data }) => {
          //     loading.value = false;
          //     submitButton.value?.removeAttribute("data-kt-indicator");
          //     if (data.code == 0) {
          //       showFormSubmitSuccessMsg();
          //     } else {
          //       showServerErrorMsg(data);
          //     }
          //   })
          //   .catch((error) => {
          //     console.log(error);
          //   });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const backToList = () => {
      commonBackToList(router, "/transport-management/shipments");
    };

    onBeforeMount(() => {
      getFromInfo();
    });

    onMounted(() => {
      setModuleBCN(t, route, router);
      if (!attrs.loading) {
        detailLoading.value = false;
        const data = attrs.detailData as InspectionDetail;
        inspectionFormData.value = data;
      }
    });

    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    return {
      t,
      formatDateTime,
      InspectionStatus,
      InspectionStage,
      loading,
      detailLoading,
      options,
      formData,
      inspectionFormData,
      exceptionProductRef,
      printPreviewRef,
      receivingPackageRef,
      updateDoubleSaleArticleModalRef,
      formRef,
      rules,
      submitButton,
      itemId,
      itemResult,
      itemQty,
      itemScanNumber,
      itemIndex,
      getMissingQtyTotal,
      getStockInTotalQty,
      isEditStockIn,
      isDisabledSubmitQc,
      getPrintedSheet,
      getPrintedSheetAll,
      getExceptionProduct,
      editDoubleSaleArticle,
      hideEditDoubleSaleArticle,
      editDoubleSaleArticleAdd,
      updateDoubleSaleArticle,
      getDoubleSaleArticleItemGuids,
      editMismatchedSku,
      editMissingResult,
      checkedStockIn,
      submitQuality,
      stockIn,
      getFromInfo,
      updateNumber,
      debounceScan,
      submit,
      backToList,
    };
  },
});
